import { styled, css } from 'styled-components'
import { onMediumScreen } from 'mixtiles-web-common'
import { TILES_SOURCE } from '../../components/TilesProvider/TilesProvider.consts'

export const HeaderContainer = styled.header<{
  $sticky?: boolean
  $noBorder?: boolean
  $hidden?: boolean
  $tilesSource: TILES_SOURCE
  $topContentAvailable: boolean
  $absolute?: boolean
  $background?: string
}>`
  position: relative;
  border-bottom: ${(props) => (props.$noBorder ? 0 : 1)}px solid
    ${(props) => props.theme.colors.grayMedium};
  z-index: ${({ $tilesSource }) => {
    return $tilesSource === TILES_SOURCE.JUMBLE ? 1402 : 100
  }};

  ${({ $topContentAvailable }) =>
    $topContentAvailable &&
    css`
      background: ${({ theme }) => theme.colors.grayAccent};
    `}

  ${(props) =>
    props.$sticky &&
    css`
      position: sticky;
      top: 0;
    `}

  transition: opacity 0.2s ease-in-out;

  background: ${(props) =>
    props.$background || props.theme.colors.grayBackgroundWhite};

  ${({ $hidden }) =>
    $hidden === true &&
    css`
      opacity: 0;
      transition-delay: 0s;
      pointer-events: none;
    `}

  ${({ $hidden }) =>
    $hidden === false &&
    css`
      opacity: 1;
      transition-delay: 0.3s;
    `}

  ${({ $absolute }) =>
    $absolute &&
    css`
      box-sizing: border-box;
      position: absolute;
      width: 100%;
    `}
`

export const MenuStrip = styled.div<{
  $topContentAvailable: boolean
  $background?: string
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;

  padding-right: 12px;
  padding-left: 12px;

  ${onMediumScreen()} {
    height: 72px;
  }

  ${({ $topContentAvailable, $background }) =>
    $topContentAvailable &&
    $background !== 'transparent' &&
    css`
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.01);
      border-top: 1px solid ${({ theme }) => theme.colors.grayMedium};
      border-radius: 16px 16px 0px 0px;
      ${onMediumScreen()} {
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.03);
        border-radius: 20px 20px 0px 0px;
      }
    `}
`

export const ButtonContainer = styled.div``

export const TitleContent = styled.div`
  width: 160px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
`
