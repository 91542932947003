import { styled } from 'styled-components'

export const Clickable = styled.div<{
  $color?: string
}>`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &:hover {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    transition: all 0.2s;
  }
`
