import * as S from './styles'
import { MenuIcon } from '../../../icons'
import { useSideMenu } from '../../../components/SideMenu/SideMenuProvider'

export default function MenuButton({
  color,
  testId,
}: {
  color?: string
  testId?: string
}) {
  const { open } = useSideMenu()
  return (
    <S.Clickable
      aria-controls="mixtiles-menu"
      aria-haspopup="true"
      onClick={open}
      data-testid={testId || 'menu-button'}
      $color={color}
    >
      <MenuIcon color={color} />
    </S.Clickable>
  )
}
