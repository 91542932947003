import * as S from './styles.js'
import { ChatIcon } from '../../../icons'
import { intercomService } from '../../../services/Analytics/platforms/intercom'
import { analytics } from '../../../services/Analytics/Analytics'

export default function ChatSupportButton({
  onChatOpen = () => {},
  color,
}: {
  onChatOpen?: () => void
  color?: string
}) {
  function handleClick() {
    intercomService.show()
    analytics.track('Talk To Us Tapped', { Source: 'Top Bar' })
    onChatOpen()
  }

  return (
    <S.Clickable onClick={handleClick} $color={color}>
      <ChatIcon color={color} />
    </S.Clickable>
  )
}
