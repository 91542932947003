import React, { Children, type PropsWithChildren, type ReactNode } from 'react'
import * as S from './TopBar.styles'
import MixtilesLogo from './Logo/MixtilesLogo.js'
import ChatSupportButton from './Buttons/ChatSupportButton.jsx'
import { useTilesSource } from '../../components/TilesProvider/TilesSourceContext'
import MenuButton from './Buttons/MenuButton'

/**
 * A modular app Top Bar
 *
 * @example The simplest way to use the Top Bar
 * <TopBar>
 *
 * @example You can replace the default left or right buttons
 * <TopBar rightButton={<CartButton />} />
 * or
 * <TopBar leftButton={<BackButton />} />
 * or omit a button
 * <TopBar leftButton={null} />
 *
 * @example You can modify the logo
 * <TopBar>
 *    <TopBar.TitleContent>
 *      Easyplant
 *    </TopBar.TitleContent>
 * </TopBar>
 *
 * @example You can add content above or below the Top Bar body, i.e: add a discount banner
 * <TopBar>
 *   <TopBar.TopContent>
 *    25% OFF all tiles until 1st of September!
 *   </TopBar.TopContent>
 *
 *   <TopBar.BottomContent>...</TopBar.BottomContent>
 * </TopBar>
 */

export type TopBarProps = {
  leftButton?: ReactNode
  rightButton?: ReactNode
  sticky?: boolean
  noBorder?: boolean
  backgroundColor?: string
  absolute?: boolean
  hidden?: boolean
  className?: string
  iconsColor?: string
}
export default function TopBar({
  leftButton,
  rightButton,
  children,
  sticky,
  noBorder,
  backgroundColor,
  absolute,
  hidden,
  className,
  iconsColor,
}: PropsWithChildren<TopBarProps>) {
  let { topContent, bottomContent, titleContent } = extractChildren(children)
  const { tilesSource } = useTilesSource()
  const hasTopContent = topContent.length > 0

  if (!leftButton && leftButton !== null) {
    leftButton = <MenuButton color={iconsColor} />
  }

  if (!rightButton && rightButton !== null) {
    rightButton = <ChatSupportButton color={iconsColor} />
  }

  if (!titleContent) {
    titleContent = <MixtilesLogo color={iconsColor} />
  }

  return (
    <S.HeaderContainer
      $sticky={sticky}
      $tilesSource={tilesSource}
      $noBorder={noBorder}
      $hidden={hidden}
      $topContentAvailable={hasTopContent}
      $absolute={absolute}
      $background={backgroundColor}
      className={className}
    >
      {topContent}
      <S.MenuStrip
        $topContentAvailable={hasTopContent}
        $background={backgroundColor}
      >
        <S.ButtonContainer>{leftButton}</S.ButtonContainer>
        <S.TitleContent>{titleContent}</S.TitleContent>
        <S.ButtonContainer>{rightButton}</S.ButtonContainer>
      </S.MenuStrip>
      {bottomContent}
    </S.HeaderContainer>
  )
}

/*
 "Identity" components that serve as content sections.

 Their usage is as follows:
 <TopBar>
    <TopBar.TopContent>This will appear above the top bar</TopBar.TopContent>
 </TopBar>
 */
TopBar.TitleContent = function TitleContent({ children }: PropsWithChildren) {
  return <>{children}</>
}
TopBar.TopContent = function TopContent({ children }: PropsWithChildren) {
  return <>{children}</>
}
TopBar.BottomContent = function BottomContent({ children }: PropsWithChildren) {
  return <>{children}</>
}

function extractChildren(children?: ReactNode) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childrenArray = Children.toArray(children) as any[]

  return {
    topContent: childrenArray.filter(
      (child) => child.type === TopBar.TopContent
    ),
    titleContent: childrenArray.find(
      (child) => child.type === TopBar.TitleContent
    ),
    bottomContent: childrenArray.find(
      (child) => child.type === TopBar.BottomContent
    ),
  }
}
